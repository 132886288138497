import { NextDynamicRoute } from '@bbx/common/routing/nextDynamicRoutes'
import { AdvertSummaryList } from '@bbx/common/types/ad-detail/AdvertSummary'
import { ContextLink, findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { getAutocompleteOnlyCategories } from '@bbx/search-journey/common/api/autocompleteApiClient'
import { fetchFromContextLink } from '@bbx/search-journey/common/api/searchApiClient'
import { BapAutocompleteCategory } from '@bbx/search-journey/common/BapAutocompleteResponse'
import { useEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { buildSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { saveSearchResultIntoSessionStorage } from '@bbx/search-journey/common/lib/searchResult-sessionStorage'
import { ViewMode } from '@bbx/search-journey/common/lib/viewModeHelper'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { trackTopAdsImpression } from '@bbx/search-journey/sub-domains/search/components/common/result-list/TopAds/topAdsTagging'
import { toastSearchError } from '@bbx/search-journey/sub-domains/search/lib/toastSearchError'
import { dismissAllToasts } from '@wh-components/core/Toast/Toast'
import { useAbortController } from '@wh/common/chapter/api/useAbortController'
import { useScreenSize } from '@wh/common/chapter/components/UserAgentProvider/useUserAgent'
import { useIdleCallback } from '@wh/common/chapter/hooks/useIdleCallback'
import { scrollToTop } from '@wh/common/chapter/lib/commonHelpers'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { BbxRouter } from '@wh/common/chapter/lib/routing/bbxRouter'
import { getQueryParams } from '@wh/common/chapter/lib/urlHelpers'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { useCallback, useEffect, useRef, useState } from 'react'

export const useResultListSearch = (
    initialSearchResult: SearchResult,
    onTriggeredSearchSucceeded: () => void,
    prefetchPage: NextDynamicRoute,
    initialCategorySuggestions?: BapAutocompleteCategory[] | undefined,
    setViewMode?: (viewMode: ViewMode, options: { setCookie: boolean }) => void,
) => {
    const [currentSearchResult, setCurrentSearchResult] = useState<SearchResult>(initialSearchResult)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    // isLoadingTopAds is separate from isLoading because on SSR, the result list should already be rendered, but the top ads will only be loaded client side
    const [isLoadingTopAds, setIsLoadingTopAds] = useState<boolean>(true)
    const [topAds, setTopAds] = useState<AdvertSummaryList | undefined>(undefined)
    const [categorySuggestions, setCategorySuggestions] = useState<BapAutocompleteCategory[] | undefined>(initialCategorySuggestions)
    const { createAbortSignal, abort } = useAbortController()
    const topAdsTaggingData = useEmptyTaggingData()
    const screenSize = useScreenSize()
    const router = useRouter()
    const routerRef = useRef(router)
    routerRef.current = router

    const fetchTopAds = async (searchResult: SearchResult) => {
        try {
            const fetchTopAdsLink = findContextLinkWithId('fetchTopAds', searchResult.searchContextLinks)

            if (!fetchTopAdsLink) {
                return
            }

            const newTopAds = await fetchFromContextLink<AdvertSummaryList>(fetchTopAdsLink, undefined, undefined, {
                'Cache-Control': 'no-cache',
            })

            setTopAds(newTopAds)
        } catch (_error) {
            // gracefully ignore error in top ads request
        }

        setIsLoadingTopAds(false)
    }

    useEffect(() => {
        fetchTopAds(initialSearchResult)
    }, [initialSearchResult, prefetchPage])

    useEffect(() => {
        if (topAds) {
            trackTopAdsImpression('ad_widget_loaded', topAds, initialSearchResult.verticalId, topAdsTaggingData)
        }
    }, [topAds, initialSearchResult.verticalId, topAdsTaggingData])

    useIdleCallback(() => routerRef.current.prefetch(prefetchPage))

    useEffect(() => {
        saveSearchResultIntoSessionStorage(currentSearchResult)
    }, [currentSearchResult])

    const triggerSearch = useCallback(
        async (...[searchLink, additionalParams, options]: Parameters<SearchCallback>) => {
            const { disableUpdateWindowLocation, disableScrollToTopOnSmall } = options ?? {}
            abort()
            dismissAllToasts()
            NProgress.start()
            setIsLoading(true)
            setIsLoadingTopAds(true)
            try {
                const newSearchResultPromise = fetchFromContextLink<SearchResult>(searchLink, additionalParams, createAbortSignal())

                const newCategorySuggestionsPromise = getNewCategorySuggestions(
                    searchLink,
                    prefetchPage === NextDynamicRoute.BAP_DETAIL_PAGE,
                    options?.loadCategorySuggestions,
                    categorySuggestions,
                    additionalParams,
                )

                const [newSearchResult, newCategorySuggestions] = await Promise.all([newSearchResultPromise, newCategorySuggestionsPromise])

                // INFO: we need to update the window.location BEFORE calling setCurrentSearchResult because the latter will
                // trigger tagging which needs the correct url as our tracking implementation extracts query parameters from it
                const isSearchAgentResultList = !!newSearchResult.lastUserAlertViewedDate
                if (!disableUpdateWindowLocation && !isSearchAgentResultList) {
                    await updateWindowLocation(newSearchResult)
                } else if (!disableUpdateWindowLocation && isSearchAgentResultList) {
                    await updateWindowLocationForSearchAgentResult(newSearchResult)
                }

                setCurrentSearchResult(newSearchResult)
                setViewMode?.(newSearchResult?.metaData?.viewMode === 'GRID_VIEW' ? 'grid' : 'list', { setCookie: false })
                setCategorySuggestions(newCategorySuggestions)
                onTriggeredSearchSucceeded?.()

                NProgress.done()
                setIsLoading(false)
                if (screenSize === 'phone' && !disableScrollToTopOnSmall) {
                    scrollToTop('auto')
                }

                fetchTopAds(newSearchResult)
            } catch (error) {
                const apiError_ = wrapInApiErrorIfNecessary(error)
                if (!apiError_.isAbort) {
                    toastSearchError()
                } else {
                    return
                }

                NProgress.done()
                setIsLoading(false)
                setIsLoadingTopAds(false)
            }
        },
        [abort, createAbortSignal, prefetchPage, categorySuggestions, setViewMode, onTriggeredSearchSucceeded, screenSize],
    )

    const resetContextLink = findContextLinkWithId('searchResetLink', currentSearchResult.searchContextLinks)
    const canResetSearch = typeof resetContextLink !== 'undefined'
    const resetSearch = useCallback(() => {
        if (resetContextLink) {
            triggerSearch(resetContextLink)
        }
    }, [resetContextLink, triggerSearch])

    return {
        currentSearchResult,
        topAds,
        triggerSearch,
        canResetSearch,
        resetSearch,
        isLoading,
        isLoadingTopAds,
        abortRequest: abort,
        suggestedCategories: categorySuggestions,
    }
}

const getNewCategorySuggestions = (
    searchLink: ContextLink,
    isBap: boolean,
    loadCategorySuggestions: boolean | undefined,
    categorySuggestions: BapAutocompleteCategory[] | undefined,
    additionalParams?: Record<string, string | string[]>,
) => {
    const queryParams = getQueryParams(searchLink.relativePath || '')
    const keyword = additionalParams?.keyword ? additionalParams?.keyword : queryParams.keyword
    return isBap && keyword && loadCategorySuggestions
        ? getAutocompleteOnlyCategories(`${keyword}`)
        : Promise.resolve(keyword ? categorySuggestions : [])
}

const updateWindowLocation = async (searchResult: SearchResult) => {
    const url = findContextLinkWithId('resultListSeoNavigatorLink', searchResult.searchContextLinks)

    if (url) {
        await BbxRouter.push({ href: buildSeoUrl(url.uri), clientSideRouting: true, options: { shallow: true, scroll: false } })
    }
}

const updateWindowLocationForSearchAgentResult = async (searchResult: SearchResult) => {
    const url = findContextLinkWithId('resultListSeoNavigatorLink', searchResult.searchContextLinks)?.uri
    if (url) {
        const page = url.match(/page=(\d+)/)
        if (page) {
            const currentUrl = new URL(location.href)
            currentUrl.searchParams.set('page', page[1])
            await BbxRouter.push({ href: currentUrl.toString(), clientSideRouting: true, options: { shallow: true, scroll: false } })
        }
    }
}
